import axios from "axios"; //引入axios
import Qs from "qs"; //引入qs，用来序列化post类型的数据，否则后端无法接收到数据
import {SITE_URL_API} from '@/config/site'
import router from '../router'
import {Message} from 'element-ui'
import logout from './logout'




let toastOpen = true
const api_request = ({url, method = 'get', parm, isToken = true,toast = true,responseType = 'json'})=>{
  // 设置post请求头
  // axios.defaults.headers.common["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";

  if(isToken){
    let token = localStorage.getItem("token")
    if(token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }else{
      // logout()
    }

  }
  axios.defaults.withCredentials = false; //在跨域请求时，不会携带用户凭证；返回的 response 里也会忽略 cookie
  
  const config = {
      url: SITE_URL_API + url,
      method,
      timeout: 60000,
      responseType
  } 
  // 构造请求 config

  // 判断请求类型 get post
  const paramType = ['get', 'delete'].indexOf(method) !== -1 ? 'params' : 'data'
  config[paramType] = parm
  //参数序列化
  config.paramsSerializer = params => {
      return Qs.stringify(params, { arrayFormat: 'repeat' });
  }
  return new Promise((resolve, reject)=>{
    axios.request(config).then((res)=>{

      const {data, code, msg} = res.data

      if(code == 401){//登录失效
        // logout()
        if(toastOpen){
          toastOpen = false
          setTimeout(()=>{
            toastOpen = true
            Message.error(msg);

          },500)

        }
        return false
      }
      if(responseType == 'blob'){
        resolve(res.data)

        return false
        
      }
      if(code === 200){
        resolve(res.data)

      }else{
        if(toast){
          Message.error(msg);

        }
        if(res.data){
          resolve(res.data || '')
        }


      }

    }).catch((err)=>{
      // console.log(config.url)
      resolve(err.data || '')

    })
    
  })
}

export { api_request };
