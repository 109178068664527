<template>
	<div id="app" class="">
		<router-view></router-view>
	</div>
</template>
<script>
	export default {
		data() {
			return {

			}
		},
		created(){
		},
		mounted() {},
		destroyed() {},
		methods: {

		}
	}
</script>

<style lang="scss">
	// @import url('./assets/css/uflex.css');
	#app{
		height: 100%;
	}
</style>