
// import {api} from '@/util/request'

// const apiRequest = {
//     getRouters(parm){
//         return api.get('/getRouters', parm)
//     }
// }

// export default apiRequest
import jsonToFormData from '@/util/jsonToFormData'


import {api_request} from '@/util/request'

const apiRequest = {
    getRegionStat(parm){ // 获取省份供应商数据（迁徙图）
        return api_request({
            url: `/srm/receivingCompany/getRegionStat`, 
            method: 'get',
            parm,
            isToken: false
        })
    },
    getMaterialsReceiveStat(parm){ // 获取大品类中供应商数量
        return api_request({
            url: `/srm/receivingCompany/getMaterialsReceiveStat`, 
            method: 'get',
            parm,
            isToken: false
        })
    },
    getMarketPrice(parm){ // 获取今日行情价
        return api_request({
            url: `/srm/receivingCompany/getMarketPrice`, 
            method: 'get',
            parm,
            isToken: false
        })
    },
    getMarketPriceAll(parm){ // 获取行情价涨跌（所有）
        return api_request({
            url: `/bigdata/getMarketPriceAll`, 
            method: 'get',
            parm,
            isToken: false
        })
    },
    getCompanyStat(parm){ // 获取公司数量
        return api_request({
            url: `/bigdata/getCompanyStat`, 
            method: 'get',
            parm,
            isToken: false
        })
    },
    getRecyclePrice(parm){ // 获取今日回收价
        return api_request({
            url: `/srm/receivingCompany/getRecyclePrice`, 
            method: 'get',
            parm,
            isToken: false
        })
    },
    getReferencePriceAll(parm){ // 获取今日回收价
        return api_request({
            url: `/bigdata/getReferencePriceAll`, 
            method: 'get',
            parm,
            isToken: false
        })
    },
    getMarketPriceRange(parm){ // 获取物料十天价格
        return api_request({
            url: `/bigdata/getMarketPriceRange`, 
            method: 'get',
            parm,
            isToken: false
        })
    },
    getMarketPriceCube(parm){ // 获取物料价格（方块）
        return api_request({
            url: `/bigdata/getMarketPriceCube`, 
            method: 'get',
            parm,
            isToken: false
        })
    },
    getReferencePriceCube(parm){ // 获取回收物料价格（方块）
        return api_request({
            url: `/bigdata/getReferencePriceCube`, 
            method: 'get',
            parm,
            isToken: false
        })
    },
    getNewReceivingCompanyCount(parm){ // 获取新增供应商数量
        return api_request({
            url: `/srm/receivingCompany/getNewReceivingCompanyCount`, 
            method: 'get',
            parm,
            isToken: false
        })
    },
    getDealInfo(parm){ // 获取交易信息
        return api_request({
            url: `/bigdata/getDealInfoNoAuth`, 
            method: 'get',
            parm,
            isToken: false
        })
    },
    getOrderCountInfo(parm){ // 获取订单完成情况
        return api_request({
            url: `/bigdata/getOrderCountInfo`, 
            method: 'get',
            parm,
            isToken: false
        })
    },
    getFactory(parm){ // 获取排行榜
        return api_request({
            url: `/bigdata/getFactoryNoAuth`, 
            method: 'get',
            parm,
            isToken: false
        })
    },
    getMatter(parm){ // 获取物料排行榜
        return api_request({
            url: `/bigdata/getMatterNoAuth`, 
            method: 'get',
            parm,
            isToken: false
        })
    },
    
}

export default apiRequest