import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import '@/util/rem'

import ElementUI from 'element-ui'
Vue.use(ElementUI)

import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/uflex.css';
import '@/assets/css/common.scss';

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)
//封装接口的方法
import apiRequest from '@/api/index'
Vue.prototype.$api = apiRequest


Vue.config.productionTip = false


new Vue({
	router,
	store,
	ElementUI,
	render: h => h(App),
}).$mount('#app')