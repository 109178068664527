import Vue from 'vue'
import VueRouter from 'vue-router'
// import home from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('../views/index2.vue')
	},
	{
		path: '/bigData',
		name: 'bigData',
		component: () => import('../views/index.vue')
	},
	{
		path: '/index',
		name: 'index',
		component: () => import('../views/index3.vue')
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

// 导航守卫：使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
// router.beforeEach((to, from, next) => {
// 	if (to.path === '/login') {
// 		next();
// 	} else {

// 		let token = localStorage.getItem("token");

// 		if (!token) {
// 			console.log('未登录')
// 			next('/login');
// 		} else {
// 			next();
// 		}
// 	}
// });


// 避免了到当前位置的冗余导航
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location)
}

export default router
